<template>
  <b-row class="match-height">
    <b-col lg="12">
      <HeaderSIP />
    </b-col>

    <b-col lg="12">
      <BodySIP />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

import HeaderSIP from "./header.vue";
import BodySIP from "./body.vue";

export default {
  components: {
    BRow,
    BCol,

    HeaderSIP,
    BodySIP,
  },
};
</script>
