<template>
  <b-card-code title="">
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <!-- kiri -->
          <b-col md="9">
            <b-form-group label="ID SIP" label-for="name">
              <b-form-input
                id="name"
                v-model="formSip.id_sip"
                placeholder="Masukkan ID SIP"
              />
            </b-form-group>

            <!-- <b-form-group label="Surat permohonan kepada Kepala Dinas Kesehatan diketahui oleh organisasi profesi" label-for="name">
              <b-form-file
                v-model="file"
                placeholder="Pilih file anda"
                drop-placeholder="Drop disini ..."
                @change="handleImage(e)"
                accept="image/*"
              />
            </b-form-group> -->

            <b-form-group
              label="Surat keterangan sudah tidak berpraktik dari sarana kesehatan (bagi berpraktik di sarana kesehatan) atau surat pernyataan sudah tidak berpraktik (untuk praktik mandiri)"
              label-for="name"
            >
              <b-form-file
                v-model="file2"
                placeholder="Pilih file anda"
                drop-placeholder="Drop disini ..."
                @change="handleImage2(e)"
                accept="image/*"
              />
            </b-form-group>

            <b-form-group
              label="Scan SIP asli."
              label-for="name"
            >
              <b-form-file
                v-model="file3"
                placeholder="Pilih file anda"
                drop-placeholder="Drop disini ..."
                @change="handleImageScan(e)"
                accept="image/*"
              />
            </b-form-group>

            <!-- <b-form-group
              label="Upload Surat Izin Praktik (SIP) asli yang dicabut atau surat kehilangan dari kepolisian jika SIP asli yang dicabut hilang"
              label-for="name"
            >
              <b-form-file
                v-model="file3"
                placeholder="Pilih file anda"
                drop-placeholder="Drop disini ..."
                @change="handleImage3(e)"
                accept="image/*"
              />
            </b-form-group> -->
          </b-col>
        </b-row>

        <!-- button -->
        <b-row>
          <b-col cols="12" align="end" class="mt-2">
            <b-button variant="secondary" @click="backButton()" class="mr-1">
              Kembali
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="submitUpdateSIP()"
            >
              Simpan
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import axios from "@axios";
import vSelect from "vue-select";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BPagination,
  BFormFile,
  BOverlay,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BMedia,
  BAvatar,
  BMediaBody,
  BMediaAside,
  BAvatarGroup,
  BImg,
  BInputGroupPrepend,
  BCard,
  BCardText,
  BCardBody,
  BDropdownItem,
  BDropdown,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";

export default {
  components: {
    BCardCode,
    VBTooltip,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BPagination,
    VueGoodTable,
    BFormFile,
    BOverlay,
    vSelect,
    BFormSelect,
    BBadge,
    BFormTextarea,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
    BImg,
    BInputGroupPrepend,
    BCard,
    BCardBody,
    BCardText,
    BDropdownItem,
    BDropdown,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      configs: {
        allowInput: {
          allowInput: true,
          dateFormat: "d-m-Y",
        },
      },

      file: null,
      file2: null,
      file3: null,

      combo_profesi: [],
      combo_syarat: [],

      showSTR: false,
      showCaraPerawatan: false,

      sip_stts: 0,

      lastPathParams: "",

      formSip: {
        id_pendaftar: "",
        id_sip: "",
      },

      id_sip: "",

      // end
    };
  },

  beforeMount() {
    this.lastPathParams = window.location.pathname.split("/").pop();

    this.formSip.id_pendaftar = localStorage.getItem("id_pendaftar");

    if (this.lastPathParams === "ubah") {
      this.getIdData();
    }
  },

  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true;
        return this.dir;
      }
      this.dir = false;
      return this.dir;
    },
  },

  methods: {
    getIdData() {
      return new Promise(() => {
        axios
          .get("pencabutan_sip/get_pencabutan_sip/" + this.$route.params.idSIP)
          .then((res) => {
            this.formSip.id_pendaftar = res.data.content[0].id_pendaftar;
            this.formSip.id_sip = res.data.content[0].id_sip;
            this.formSip.sip_asli_url = res.data.content[0].sip_asli_url;
            this.formSip.surat_keterangan_praktik_url =
              res.data.content[0].surat_keterangan_praktik_url;
            this.formSip.surat_permohonan_url =
              res.data.content[0].surat_permohonan_url;

            this.id_sip = res.data.content[0].id;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
            }
          });
      });
    },

    submitUpdateSIP() {
      if (this.formSip.id_sip === "") {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "ID SIP Wajib Diisi",
            icon: "XCircleIcon",
            variant: "danger",
          },
        });
      } else {
        if (this.lastPathParams === "tambah") {
          return new Promise(() => {
            axios
              .post("pencabutan_sip/create_pencabutan_sip", this.formSip)
              .then((res) => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Pengajuan Pencabutan SIP Berhasil`,
                    icon: "CheckCircleIcon",
                    variant: "success",
                    text: `Pengajuan Pencabutan SIP Berhasil`,
                  },
                });
                this.$router.push("/pencabutan-sip");
              })
              .catch((error) => {
                if (error.response.status == 401) {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: error.response.data.detail,
                      icon: "XCircleIcon",
                      variant: "danger",
                    },
                  });

                  this.$router.push("/login");
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: error.response.data.detail,
                      icon: "XCircleIcon",
                      variant: "danger",
                    },
                  });
                }
              });
          });
        } else {
          return new Promise(() => {
            axios
              .put(
                "pendaftar/update_sip?id_sip=" + this.$route.params.idSIP,
                this.formSip
              )
              .then((res) => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Ubah Data Berhasil`,
                    icon: "CheckCircleIcon",
                    variant: "success",
                    text: `Ubah Data SIP Berhasil`,
                  },
                });
                this.$router.push("/pencabutan-sip");
              })
              .catch((error) => {
                if (error.response.status == 401) {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: error.response.data.detail,
                      icon: "XCircleIcon",
                      variant: "danger",
                    },
                  });

                  this.$router.push("/login");
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: error.response.data.detail,
                      icon: "XCircleIcon",
                      variant: "danger",
                    },
                  });
                }
              });
          });
        }
      }
    },

    handleImage() {
      const file = document.querySelector("input[type=file]").files[0];
      const reader = new FileReader();

      let rawImg;
      reader.onloadend = () => {
        rawImg = reader.result;
        this.formSip.surat_permohonan_url = rawImg;
      };
      reader.readAsDataURL(file);
    },

    handleImage2() {
      const file = document.querySelector("input[type=file]").files[0];
      const reader = new FileReader();

      let rawImg;
      reader.onloadend = () => {
        rawImg = reader.result;
        this.formSip.surat_keterangan_praktik_url = rawImg;
      };
      reader.readAsDataURL(file);
    },

    handleImageScan() {
      const file = document.querySelector("input[type=file]").files[0];
      const reader = new FileReader();

      let rawImg;
      reader.onloadend = () => {
        rawImg = reader.result;
        this.formSip.sip_asli_url = rawImg;
      };
      reader.readAsDataURL(file);
    },

    handleImage3() {
      const file = document.querySelector("input[type=file]").files[0];
      const reader = new FileReader();

      let rawImg;
      reader.onloadend = () => {
        rawImg = reader.result;
        this.formSip.sip_asli_url = rawImg;
      };
      reader.readAsDataURL(file);
    },

    backButton() {
      this.$router.push("/pencabutan-sip");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
